export default {
  namespaced: true,
  actions: {
    update({ commit }, newRoute) {
      commit('UPDATE_ROUTE', newRoute);
    },
    setExitReason({ commit }, reason) {
      commit('SET_EXIT_REASON', reason);
    },
    messageForNext({ commit }, message) {
      commit('SET_MESSAGE', message);
    }
  },
  mutations: {
    UPDATE_ROUTE: (state, route) => {
      state.previous = Object.assign({}, state.current);
      state.current = Object.assign({}, { route });
    },
    SET_EXIT_REASON: (state, reason) => {
      state.current.exitReason = reason;
    },
    SET_MESSAGE: (state, message) => {
      state.current.message = Object.assign({}, message);
    }
  },
  getters: {
    previous: state => state.previous,
    currentExitReason: state => state.current.exitReason
  },
  state: {
    previous: {},
    current: {}
  }
};

export const PermissionLevel = Object.freeze({
  Execute: 'x',
  Read: 'r',
  Write: 'w'
});

export function hasPermission(grantedPermissions, requiredPermissions) {
  if (!requiredPermissions) {
    return true;
  }
  const allowed = requiredPermissions.every(requiredPermission => {
    const requiredPermissionCode = Object.keys(requiredPermission)[0];
    const requiredPermissionLevel = requiredPermission[requiredPermissionCode];
    return grantedPermissions.some(
      grantedPermission =>
        grantedPermission.permissionCode === requiredPermissionCode &&
        grantedPermission.permissionLevel.charCodeAt(0) >= requiredPermissionLevel.charCodeAt(0)
    );
  });
  return allowed;
}
export function hasReadPermissionForCode(permissionCode, grantedPermissions) {
  if (Array.isArray(permissionCode)) {
    return permissionCode.some(code => hasReadPermissionForCode(code, grantedPermissions));
  }
  const requiredPermission = {};
  requiredPermission[permissionCode] = PermissionLevel.Read;
  return hasPermission(grantedPermissions, [requiredPermission]);
}
export function hasCreatePermissionForCode(permissionCode, grantedPermissions) {
  if (Array.isArray(permissionCode)) {
    return permissionCode.some(code => hasCreatePermissionForCode(code, grantedPermissions));
  }
  const requiredPermission = {};
  requiredPermission[permissionCode] = PermissionLevel.Write;
  return hasPermission(grantedPermissions, [requiredPermission]);
}
export function hasEditPermissionForCode(permissionCode, grantedPermissions) {
  if (Array.isArray(permissionCode)) {
    return permissionCode.some(code => hasEditPermissionForCode(code, grantedPermissions));
  }
  const requiredPermission = {};
  requiredPermission[permissionCode] = PermissionLevel.Write;
  return hasPermission(grantedPermissions, [requiredPermission]);
}
export function hasDeletePermissionForCode(permissionCode, grantedPermissions) {
  if (Array.isArray(permissionCode)) {
    return permissionCode.some(code => hasDeletePermissionForCode(code, grantedPermissions));
  }
  const requiredPermission = {};
  requiredPermission[permissionCode] = PermissionLevel.Execute;
  return hasPermission(grantedPermissions, [requiredPermission]);
}

import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie
import { hasPermission } from '@/utils/permission';

NProgress.configure({ showSpinner: false });

const whiteList = [
  /^(\/documents-portal\/)[a-z0-9\-\_\=]+$/i,
  '/forgot-password',
  /^(\/new-password\/)[a-z0-9\-\_\=\.]+$/i,
  '/login',
  '/404'
]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  store.dispatch('increasePageVersion');
  store.dispatch('editor/clearForms');
  NProgress.start(); // start progress bar
  if (getToken()) {
    // determine if there has token
    /* has token */
    if (to.path === '/login' || to.path === '/forgot-password') {
      next({ name: 'home' });
      NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
    } else {
      if (!store.state.authentication.email) {
        // Determine if the current user has finished pulling user_info information
        store
          .dispatch('getUserInfo', { withPermissions: true })
          .then(() => {
            next({
              ...to,
              replace: true
            }); // hack method make sure addRoutes completed ,set the replace: true so the navigation will not leave a history record
          })
          .catch(err => {
            store.dispatch('frontEndLogout').then(() => {
              Message.error(err);
              next({ path: '/' });
            });
          });
      } else {
        if (hasPermission(store.getters.permissions, to.meta.permissions)) {
          next();
        } else {
          next({ path: '/401', query: { noGoBack: true }, replace: true });
        }
      }
    }
  } else {
    /* has no token */
    const checkWhiteList = allowed => (typeof allowed === 'string' ? allowed === to.path : allowed.test(to.path));
    if (whiteList.some(checkWhiteList)) {
      // In the free login whitelist, go directly
      next();
    } else {
      next(`/login?redirect=${to.path}`); // Otherwise redirect all to the login page
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(to => {
  store.dispatch('router/update', to);
  NProgress.done(); // finish progress bar
});

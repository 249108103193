<template>
  <action-dialog
    type="question"
    :callback="dialogCallback"
    :title="title"
    :before-submit="beforeSubmit"
    :message="message"
    :cancel-button-text="cancelButtonText"
    :confirm-button-text="confirmButtonText"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    :show-cancel="showCancel"
    :show-close="showClose"
    :close-on-press-escape="closeOnPressEscape"
  >
    <el-form ref="form" :rules="rules" :model="form" label-position="top">
      <el-form-item prop="text" :label="message">
        <MDinput ref="input" v-model="form.text" />
      </el-form-item>
    </el-form>
  </action-dialog>
</template>
<script>
import i18n from '@/i18n';
import MDinput from '@/components/MDinput';
import ActionDialog from '@/components/ActionDialog';
import { validatorRequire } from '@/utils/validators';
export default {
  components: {
    ActionDialog,
    MDinput
  },
  data() {
    return {
      defaultValue: null,
      callback: null,
      type: undefined,
      title: undefined,
      message: undefined,
      cancelButtonText: undefined,
      confirmButtonText: undefined,
      dangerouslyUseHTMLString: false,
      width: undefined,
      closeOnClickModal: undefined,
      closeOnPressEscape: undefined,
      showCancel: undefined,
      showClose: undefined,
      i18n,
      form: {},
      rules: undefined
    };
  },
  mounted() {
    this.$set(this.form, 'text', this.defaultValue);

    this.$nextTick(_ => {
      this.$refs.input.focus();
    });
  },
  created() {
    this.rules = {
      text: [{ validator: validatorRequire }]
    };
  },
  methods: {
    async beforeSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        throw new Error('Form not valid');
      }
    },
    dialogCallback(action) {
      this.callback(action, this.form.text);
    }
  }
};
</script>

const dayjs = require('dayjs');
/**
 * @typedef {Object} InputState
 * @property {string} Text - Input text value
 * @property {number} SelStart - Caret position
 */

/**
 * Formats date in input elements. Ported from BISAS - procedure TDateField.KeyPress
 *
 * @param {KeyboardEvent} e 'keyup' event
 * @param {string} inputValue input text value before event
 * @returns {InputState}
 */
export function parseDateInput(e, inputValue) {
  const Key = e.key;
  let Text = inputValue;
  let SelStart = e.target.selectionStart;

  if (isSpecialKey(e)) {
    return;
  }
  e.preventDefault();

  inputValue = removeSelectedText(inputValue, e.target.selectionStart, e.target.selectionEnd);
  if (e.code === 'Backspace') {
    ({ Text, SelStart } = parseIfKeyIsBackspace(inputValue, Key, SelStart));
  } else if (e.code === 'Delete') {
    ({ Text, SelStart } = parseIfKeyIsDelete(inputValue, Key, SelStart));
  } else if (['.', '-', '/'].includes(e.key)) {
    ({ Text, SelStart } = parseIfKeyIsSeparator(inputValue, Key, SelStart));
  } else if (!e.shiftKey && e.key >= '0' && e.key <= '9') {
    ({ Text, SelStart } = parseIfKeyIsDigit(inputValue, Key, SelStart));
  }

  if (!isLegalDate(Text)) {
    return;
  }
  return { Text, SelStart };
}
function removeSelectedText(src, selectionStart, selectionEnd) {
  if (selectionStart !== selectionEnd) {
    return stringCut(src, selectionStart, selectionEnd);
  }
  return src;
}
function stringCut(str, startIndex, endIndex) {
  const chars = str.split('');
  chars.splice(startIndex, endIndex - startIndex);
  return chars.join('');
}
function isSpecialKey(e) {
  // 0=null, 9=tab, 13=enter, 16=shift, 17=control, 18=alt, 27=escape
  if ([0, 9, 13, 16, 17, 18, 27].includes(e.keyCode)) {
    return true;
  }
  if (e.ctrlKey && ['KeyA', 'KeyC', 'KeyV', 'KeyZ', 'KeyY'].includes(e.code)) {
    return true;
  }
  if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
    return true;
  }
  return false;
}
function parseIfKeyIsSeparator(Text, Key, SelStart) {
  let Dag, Maand;
  const OldSelStart = SelStart;
  let s = Padr(Text, 10);
  if (s.charAt(0) === '-') {
    Text = sDay(Date) + Copy(s, 1, 8);
    SelStart = SelStart + 2;
  }
  if (s.charAt(1) === '-') {
    s = '0' + s;
    SelStart = SelStart + 1;
  }
  if (s.charAt(2) !== '-') {
    s = Left(s, 2) + '-' + substr(s, 3);
  }
  if (s.charAt(3) === '-') {
    s = Left(s, 3) + sMonth(Date) + substr(s, 4);
  }
  if (s.charAt(4) === '-') {
    s = Left(s, 3) + '0' + substr(s, 4);
  }
  if (s.charAt(5) !== '-') {
    s = Left(s, 5) + '-' + substr(s, 6);
  }
  Text = s;
  if ([1, 2].includes(OldSelStart)) {
    Dag = Left(Text, 1);
    if (Dag > '0' && Copy(Text, 2, 1) === ' ') {
      Text = '0' + Dag + '-' + Substr(Text, 4);
    }
    SelStart = 3;
  } else if ([4, 5].includes(OldSelStart)) {
    Maand = Copy(Text, 4, 1);
    if (Maand > '0' && Copy(Text, 5, 1) === ' ') {
      Text = Left(Text, 2) + '-0' + Maand + '-' + Substr(Text, 7);
      SelStart = 6;
    }
  }
  return { Text, SelStart };
}

function parseIfKeyIsBackspace(Text, Key, SelStart) {
  if (SelStart > 0) {
    let OldSelStart = SelStart;
    if ([3, 6].includes(OldSelStart)) {
      OldSelStart--;
    }
    Text = Left(Text, OldSelStart - 1) + ' ' + Substr(Text, OldSelStart + 1);
    SelStart = OldSelStart - 1;
  }
  return { Text, SelStart };
}
function parseIfKeyIsDelete(Text, Key, SelStart) {
  if (SelStart < Text.length) {
    let OldSelStart = SelStart;
    if ([2, 5].includes(OldSelStart)) {
      OldSelStart++;
    }
    if (OldSelStart >= 6) {
      Text = Left(Text, OldSelStart) + Substr(Text, OldSelStart + 2);
    } else {
      Text = Left(Text, OldSelStart) + ' ' + Substr(Text, OldSelStart + 2);
    }
    SelStart = OldSelStart;
  }
  return { Text, SelStart };
}
function parseIfKeyIsDigit(Text, Key, SelStart) {
  let Dag, Maand;
  let OldSelStart = SelStart;
  if (Length(Text) !== 10) {
    Text = Padr(Text, 10);
  }
  Text = Copy(Text, 1, 2) + '-' + Copy(Text, 4, 2) + '-' + Substr(Text, 7);
  if (OldSelStart === 0) {
    if (Key > '3') {
      Text = '0' + Key + '-' + Substr(Text, 4);
      SelStart = 3;
    } else {
      Text = Key + ' -' + Substr(Text, 4);
      SelStart = 1;
    }
  } else if (OldSelStart === 1) {
    Dag = Left(Text, 1);
    if (Dag < '0' || Dag > '9') {
      Dag = '0';
    }
    if (Dag === '0' && Key === '0') {
      Text = '0 -' + Substr(Text, 4);
      SelStart = 1;
    } else if (Dag < '3' || (Dag === '3' && ['0', '1'].includes(Key))) {
      Text = Dag + Key + '-' + Substr(Text, 4);
      SelStart = 3;
    } else {
      if (Key > '1') {
        Text = '0' + Dag + '-0' + Key + '-' + Substr(Text, 7);
        SelStart = 6;
      } else {
        Text = '0' + Dag + '-' + Key + ' -' + Substr(Text, 7);
        SelStart = 4;
      }
    }
  } else if (OldSelStart <= 3) {
    Dag = Left(Text, 2);
    if (Dag < '01' || Dag > '31') {
      Dag = '01';
    }
    if (Key > '1') {
      Text = Dag + '-0' + Key + '-' + Substr(Text, 7);
      SelStart = 6;
    } else {
      Text = Dag + '-' + Key + ' -' + Substr(Text, 7);
      SelStart = 4;
    }
  } else if (OldSelStart === 4) {
    Dag = Left(Text, 2);
    if (Dag < '01' || Dag > '31') {
      Dag = '01';
    }
    Maand = Copy(Text, 4, 1);
    if (Maand < '0' || Maand > '9') {
      Maand = '0';
    }
    if (Maand === '0' && Key === '0') {
      Text = Dag + '-0 -' + Substr(Text, 7);
      SelStart = 4;
    } else if (Maand < '1' || (Maand === '1' && ['0', '1', '2'].includes(Key))) {
      Text = Dag + '-' + Maand + Key + '-' + Substr(Text, 7);
      SelStart = 6;
    } else {
      Text = Dag + '-0' + Maand + '-' + Key + '   ';
      SelStart = 7;
    }
  } else {
    OldSelStart = Math.max(OldSelStart, 6);
    Dag = Left(Text, 2);
    Maand = Copy(Text, 4, 2);
    if (Dag < '01' || Dag > '31') {
      Dag = '01';
    }
    if (Maand < '01' || Maand > '12') {
      Maand = '01';
    }
    Text = Padr(Dag + '-' + Maand + '-' + Copy(Text, 7, OldSelStart - 6) + Key, 10);
    SelStart = OldSelStart + 1;
  }

  return { Text, SelStart };
}
function Length(src) {
  return src.length;
}
function Padr(string, length) {
  return string.padEnd(length);
}
function Copy(src, start, count) {
  return src.substring(start - 1, start - 1 + count);
}
function Substr(src, start) {
  return src.substring(start - 1);
}
function Left(src, length) {
  return src.substring(0, length);
}
function sDay() {
  return `${dayjs().format('D')}`;
}
function sMonth() {
  return `${dayjs().format('M')}`;
}
function substr(src, start) {
  return src.substring(start - 1);
}
function isLegalDate(str) {
  const re = /^[0-9 ]{0,2}\-?[0-9 ]{0,2}\-?[0-9 ]{0,4}$/;
  return re.test(str);
}

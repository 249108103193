import router from '@/router';

export default {
  namespaced: true,
  state: {
    disabledErrorPrefixes: [],
    disabledErrorHTTPCodes: []
  },
  actions: {
    enableErrorCodePrefix({ commit }, prefix) {
      commit('ENABLE_ERROR_PREFIX', prefix);
    },
    disableErrorCodePrefix({ commit }, prefix) {
      commit('DISABLE_ERROR_PREFIX', prefix);
    },
    enableErrorHTTPCode({ commit }, code) {
      commit('ENABLE_HTTP_RESPONSE_PREFIX', code);
    },
    disableErrorHTTPCode({ commit }, code) {
      commit('DISABLE_HTTP_RESPONSE_CODE', code);
    },
    handleRequestError({ state, dispatch }, err) {
      if (err.response) {
        if (!state.disabledErrorHTTPCodes.some(code => err.response.status === code)) {
          const { data } = err.response;
          if (Array.isArray(data)) {
            // Display error from database if that exists
            data.forEach(e => {
              const errCode = e.code || e.Code;
              const errMessage = e.message || e.Message;
              if (!state.disabledErrorPrefixes.some(prefix => errCode && errCode.startsWith(prefix))) {
                dispatch('notify/error', { message: errMessage || errCode }, { root: true });
              }
            });
          } else {
            dispatch('handleRequestErrorByStatus', err.response.status);
          }
        }
      } else {
        dispatch('notify/toast', { message: err.message }, { root: true });
      }
    },
    handleRequestErrorByStatus({ dispatch }, status) {
      if (status === 401) {
        dispatch('frontEndLogout', null, { root: true });
        router.replace({ name: 'login', query: { redirect: router.history.current.fullPath } });
      }

      dispatch('notify/requestErrorByStatus', status, { root: true });
    }
  },
  mutations: {
    ENABLE_ERROR_PREFIX: (state, prefix) => {
      const index = state.disabledErrorPrefixes.indexOf(prefix);
      if (index > -1) {
        state.disabledErrorPrefixes.splice(index, 1);
      }
    },
    DISABLE_ERROR_PREFIX: (state, prefix) => {
      const index = state.disabledErrorPrefixes.indexOf(prefix);
      if (index < 0) {
        state.disabledErrorPrefixes.push(prefix);
      }
    },
    ENABLE_HTTP_RESPONSE_PREFIX: (state, code) => {
      const index = state.disabledErrorHTTPCodes.indexOf(code);
      if (index > -1) {
        state.disabledErrorHTTPCodes.splice(index, 1);
      }
    },
    DISABLE_HTTP_RESPONSE_CODE: (state, code) => {
      state.disabledErrorHTTPCodes.push(code);
    }
  },
  getters: {
    previous: state => state.previous,
    currentExitReason: state => state.current && state.current.exitReason
  }
};

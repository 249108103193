import definitions from './definitions';
import baseGetters from './baseStore/getters';
import baseMutations from './baseStore/mutations';
import baseActions from './baseStore/actions';
import baseState from './baseStore/state';
import baseAPI from './baseAPI';

const storeModules = {};
function getApiOverrideFn(entity, fnName) {
  return (
    (entity.options && entity.options.apiOverrides && entity.options.apiOverrides[fnName]) || baseAPI[fnName](entity)
  );
}
function getStoreExtension(entity, property) {
  return entity.options && entity.options.storeExtensions && entity.options.storeExtensions[property];
}

definitions.forEach(entity => {
  const transformDto2Item = getApiOverrideFn(entity, 'transformDto2Item');
  const transformItem2Dto = getApiOverrideFn(entity, 'transformItem2Dto');
  const apiFetchList = getApiOverrideFn(entity, 'apiFetchList');
  const apiFetchOne = getApiOverrideFn(entity, 'apiFetchOne');
  const apiCreate = getApiOverrideFn(entity, 'apiCreate')(transformItem2Dto);
  const apiUpdate = getApiOverrideFn(entity, 'apiUpdate')(transformItem2Dto);
  const apiDelete = getApiOverrideFn(entity, 'apiDelete');

  storeModules[entity.store] = {
    actions: {
      ...baseActions,
      transformDto({ commit }, dto) {
        return transformDto2Item(dto);
      },
      fetchList({ commit }, params) {
        return apiFetchList(params).then(response => response);
      },
      fetchOne({ commit }, { id, params }) {
        return apiFetchOne(id, params).then(response => response);
      },
      update({ commit }, item) {
        return apiUpdate(item).then(response => response);
      },
      create({ commit }, item) {
        return apiCreate(item).then(response => response);
      },
      delete({ commit }, item) {
        return apiDelete(item).then(response => response);
      },
      ...getStoreExtension(entity, 'actions')
    },
    getters: {
      ...baseGetters,
      ...getStoreExtension(entity, 'getters')
    },
    mutations: {
      ...baseMutations,
      ...getStoreExtension(entity, 'mutations')
    },
    namespaced: true,
    state: {
      ...baseState(entity),
      ...getStoreExtension(entity, 'state')
    }
  };
});
export default storeModules;

import request from '@/utils/request';

function apiFetchList(params) {
  let url = '/auditlog';
  if (params.entity) {
    url += '/' + params.entity;
  }
  return request({
    method: 'get',
    params: params && params.query,
    url
  }).then(response => {
    response.data = { items: response.data, total: response.data.length };
    return response;
  });
}
export const apiOverrides = { apiFetchList };

export const storeExtensions = {
  actions: {
    update({ commit }, item) {
      throw new Error('Access logs are not updatable');
    },
    delete({ commit }, item) {
      throw new Error('Access logs are not deletable');
    },
    create({ commit }, item) {
      throw new Error('Access logs are not creatable');
    },
    getAuditLog({ dispatch }, { entity, id }) {
      const params = { entity, id };
      return apiFetchList(params).then(response => {
        return response.data;
      });
    }
  }
};

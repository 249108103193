export const storeExtensions = {
  actions: {
    update({ commit }, item) {
      throw new Error('Access logs are not updatable');
    },
    delete({ commit }, item) {
      throw new Error('Access logs are not deletable');
    },
    create({ commit }, item) {
      throw new Error('Access logs are not creatable');
    }
  }
};

export default [
  {
    component: () => import('@/pages/errors/401.vue'),
    hidden: true,
    path: '/401'
  },
  {
    component: () => import('@/pages/errors/403.vue'),
    hidden: true,
    path: '/403'
  },
  {
    component: () => import('@/pages/errors/404.vue'),
    hidden: true,
    path: '/404'
  }
];

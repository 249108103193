import request from '@/utils/request';
export const apiAgreementRequest = agreementRequest => {
  return request({
    data: { ...agreementRequest },

    method: 'post',

    url: '/loanagreement/addloanagreement'
  });
};

export const apiLoanagreementsGet = agreementParameters => {
  return request({
    data: { ...agreementParameters },
    method: 'get',
    url: 'loanagreement'
  });
};

function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['loanAgreementID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['loanAgreementID']
  };
  delete item['loanAgreementID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    addloanagreement({ dispatch }, agreementRequest) {
      return apiAgreementRequest(agreementRequest);
    },
    loangreementsGet({ dispatch }, loanId, agreementBy) {
      return apiLoanagreementsGet({ loanId, agreementBy });
    },
    loanagreementsByLoanId({ commit, dispatch }, loanId, agreementby) {
      commit('SET_LOADING', true);
      const payload = {
        query: { loanID: loanId, agreementBy: agreementby }
      };
      return dispatch('fetchList', payload)
        .then(async response => {
          const items = [];
          for (const dto of response.data.items) {
            const item = await dispatch('transformDto', dto);
            items.push(item);
          }
          commit('SET_LOADING', false);
          if (items.length > 0) {
            commit('UPDATE_LOANAGREEMENTS_BY_LOANID', {
              loangreements: items,
              dossierId: loanId,
              agreementBy: agreementby
            });
          }
          return items;
        })
        .catch(err => {
          console.log(err);
          commit('SET_LOADING', false);
        })
        .finally(() => {
          return [];
        });
    }
  }
};

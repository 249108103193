import { objectsAreEqualExact } from '@/utils';

class JsonDiffer {
  constructor() {
    if (this.constructor === JsonDiffer) {
      throw new Error('JsonDiffer is an abstract class');
    }
  }
  log(obj1, obj2) {
    throw new Error('JsonDiffer is an abstract class');
  }
}
class ExternalJsonDiffer extends JsonDiffer {
  constructor(url, queryNameLeft, queryNameRight) {
    super();
    this.url = url;
    this.queryNameLeft = queryNameLeft;
    this.queryNameRight = queryNameRight;
  }

  generateURL(obj1, obj2) {
    const stringified1 = JSON.stringify(obj1);
    const stringified2 = JSON.stringify(obj2);
    return (
      `${this.url}?` +
      `${this.queryNameLeft}=${encodeURIComponent(stringified1)}` +
      `&` +
      `${this.queryNameRight}=${encodeURIComponent(stringified2)}`
    );
  }
  log(obj1, obj2) {
    console.log(this.generateURL(obj1, obj2));
  }
}
class JsonViewer {
  constructor(url, queryName) {
    this.url = url;
    this.queryName = queryName;
  }

  generateURL(obj) {
    const stringified = JSON.stringify(obj);
    return `${this.url}?${this.queryName}=${encodeURIComponent(stringified)}`;
  }
  log(obj1) {
    console.log(this.generateURL(obj1));
  }
}

const DebugUtils = (function() {
  const jsonDiffProvider = new ExternalJsonDiffer('file:///C:/project/jsondiffportal/index.html', 'left', 'right');
  const jsonViewProvider = new JsonViewer('https://jsonlint.com/', 'json');

  return {
    logNotEqual(obj1, obj2) {
      if (!objectsAreEqualExact(obj1, obj2)) {
        jsonDiffProvider.log(obj1, obj2);
      }
    },
    logJsonViewer(obj) {
      jsonViewProvider.log(obj);
    }
  };
})();

export default DebugUtils;

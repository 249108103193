import { routesWithPermissions, routesWithoutPermissions } from '@/router';
import { hasPermission } from '@/utils/permission';

function filterPermittedRoutes(routes, grantedPermissions) {
  const res = [];
  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(grantedPermissions, tmp.meta && tmp.meta.permissions)) {
      if (tmp.children) {
        tmp.children = filterPermittedRoutes(tmp.children, grantedPermissions);
      }
      res.push(tmp);
    }
  });
  return res;
}

export const storeExtensions = {
  actions: {
    update({ commit }, item) {
      throw new Error('Permissions are read only');
    },
    create({ commit }, item) {
      throw new Error('Permissions are read only');
    },
    delete({ commit }, item) {
      throw new Error('Permissions are read only');
    },
    generateRoutes({ commit }, grantedPermissions) {
      // General routes for navigation sidebar
      return new Promise(resolve => {
        const routes = filterPermittedRoutes(routesWithPermissions, grantedPermissions);
        commit('SET_ROUTES', routes);
        resolve();
      });
    }
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRouters = routes;
      state.routes = routesWithoutPermissions.concat(routes);
    }
  },
  state: {
    routes: routesWithoutPermissions,
    addRouters: []
  }
};

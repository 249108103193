const baseState = function(definition) {
  return {
    items: [], // used for non paginated lists
    paginatedItems: [], // used for paginated lists
    looseItems: [], // items that are fetched but shouldn't appear in lists, eg. chosen item in pickrec. This may contain inactive items
    loading: false,
    totalItemsCount: 0,
    version: 0,
    looseVersion: 0,
    backendEntityIDField: definition.backendEntityIDField,
    permissionName: definition.permissionName
  };
};

export default baseState;

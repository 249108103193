import { tryGetValue } from '@/utils';
export default {
  actions: {
    registerOnCloseView({ commit }, { view, hook }) {
      if (!Array.isArray(view.meta.onCloseTag)) {
        view.meta.onCloseTag = [];
      }
      view.meta.onCloseTag.push(hook);
    },
    handleCloseView({ commit }, view) {
      const onCloseTag = tryGetValue(view, 'meta', 'onCloseTag');
      if (Array.isArray(onCloseTag)) {
        onCloseTag.forEach(hook => {
          try {
            hook();
          } catch (error) {
            // Do nothing
          }
        });
      }
    },
    addCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view);
    },
    addView({ dispatch }, view) {
      dispatch('addVisitedView', view);
      dispatch('addCachedView', view);
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view);
    },
    moveVisitedViewToFront({ commit }, view) {
      commit('MOVE_VISITED_VIEW_TO_FRONT', view);
    },
    delAllViews({ dispatch, state }, view) {
      return new Promise(resolve => {
        state.visitedViews.forEach(v => {
          dispatch('handleCloseView', v);
        });
        dispatch('delAllVisitedViews', view);
        dispatch('delAllCachedViews', view);
        resolve({
          cachedViews: [...state.cachedViews],
          visitedViews: [...state.visitedViews]
        });
      });
    },
    delCachedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_CACHED_VIEW', view);
        resolve([...state.cachedViews]);
      });
    },
    delAllCachedViews({ commit, state }) {
      return new Promise(resolve => {
        commit('DEL_ALL_CACHED_VIEWS');
        resolve([...state.cachedViews]);
      });
    },

    delOthersCachedViews({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_CACHED_VIEWS', view);
        resolve([...state.cachedViews]);
      });
    },
    delAllVisitedViews({ commit, state }) {
      return new Promise(resolve => {
        commit('DEL_ALL_VISITED_VIEWS');
        resolve([...state.visitedViews]);
      });
    },
    delOthersViews({ dispatch, getters, state }, view) {
      return new Promise(resolve => {
        getters['othersVisitedViews'].forEach(v => {
          dispatch('handleCloseView', v);
        });
        dispatch('delOthersVisitedViews', view);
        dispatch('delOthersCachedViews', view);
        resolve({
          cachedViews: [...state.cachedViews],
          visitedViews: [...state.visitedViews]
        });
      });
    },

    delOthersVisitedViews({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_VISITED_VIEWS', view);
        resolve([...state.visitedViews]);
      });
    },
    delView({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view);
        dispatch('delCachedView', view);
        resolve({
          cachedViews: [...state.cachedViews],
          visitedViews: [...state.visitedViews]
        });
      });
    },
    delVisitedView({ dispatch, commit, state }, view) {
      return new Promise(resolve => {
        dispatch('handleCloseView', view);
        commit('DEL_VISITED_VIEW', view);
        resolve([...state.visitedViews]);
      });
    },

    updateVisitedView({ commit }, view) {
      commit('UPDATE_VISITED_VIEW', view);
    }
  },
  getters: {
    othersVisitedViews: (state, view) => state.visitedViews.filter(v => v.path !== view.path)
  },
  mutations: {
    ADD_CACHED_VIEW: (state, view) => {
      if (state.cachedViews.includes(view.name)) {
        return;
      }
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
    },
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) {
        return;
      }
      state.visitedViews.unshift(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      );
    },

    DEL_ALL_CACHED_VIEWS: state => {
      state.cachedViews = [];
    },
    DEL_ALL_VISITED_VIEWS: state => {
      state.visitedViews = [];
    },

    DEL_CACHED_VIEW: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i);
          state.cachedViews.splice(index, 1);
          break;
        }
      }
    },
    DEL_OTHERS_CACHED_VIEWS: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i);
          state.cachedViews = state.cachedViews.slice(index, index + 1);
          break;
        }
      }
    },

    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews = state.visitedViews.slice(i, i + 1);
          break;
        }
      }
    },
    DEL_VISITED_VIEW: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
    },
    UPDATE_VISITED_VIEW: (state, view) => {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view);
          break;
        }
      }
    },
    MOVE_VISITED_VIEW_TO_FRONT: (state, view) => {
      const index = state.visitedViews.findIndex(v => v.path === view.path);
      if (index > 0) {
        const _view = state.visitedViews[index];
        state.visitedViews.unshift(_view);
        state.visitedViews.splice(index + 1, 1);
      }
    }
  },
  state: {
    cachedViews: [],
    visitedViews: []
  }
};

import Vue from 'vue';
import Main from './main.vue';
import { isVNode } from 'element-ui/src/utils/vdom';
const DialogConstructor = Vue.extend(Main);

let instance;
const instances = [];
let seed = 1;

const defaultCallback = ({ resolve, reject }) => (action, vue) => {
  if (action === 'cancel' || action === 'close') {
    resolve(action);
  } else if (action === 'confirm') {
    reject();
  }
};

const ActionDialogConfirm = function(options) {
  if (Vue.prototype.$isServer) {
    return;
  }
  options = options || {};
  const id = 'actiondialog_' + seed++;

  return new Promise((reject, resolve) => {
    instance = new DialogConstructor({
      data: options
    });

    instance.callback = defaultCallback({ resolve, reject });
    if (isVNode(options.message)) {
      instance.$slots.default = [options.message];
      options.message = 'REPLACED_BY_VNODE';
    }
    instance.id = id;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;
    instance.dom = instance.$el;
    instances.push(instance);
  });
};
export default ActionDialogConfirm;

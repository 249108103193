export default {
  actions: {
    addErrorLog({ commit }, log) {
      commit('ADD_ERROR_LOG', log);
    }
  },
  mutations: {
    ADD_ERROR_LOG: (state, log) => {
      state.logs.push(log);
    }
  },
  state: {
    logs: []
  }
};

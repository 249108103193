import request from '@/utils/request';
export const apiAgreementRequest = agreementRequest => {
  return request({
    data: { ...agreementRequest },

    method: 'post',

    url: '/loanagreement/addloanagreement'
  });
};

function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['loanID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['loanID']
  };
  delete item['loanID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    loansByDossierId({ commit, dispatch }, dossierId) {
      commit('SET_LOADING', true);
      const payload = {
        query: { dossierID: dossierId }
      };
      return dispatch('fetchList', payload)
        .then(async response => {
          const items = [];
          for (const dto of response.data.items) {
            const item = await dispatch('transformDto', dto);
            items.push(item);
          }
          commit('SET_LOADING', false);
          if (items.length > 0) {
            commit('UPDATE_LOANS_BY_DOSSIERID', { loans: items, dossierId: dossierId });
          }
          return items;
        })
        .catch(err => {
          console.log(err);
          commit('SET_LOADING', false);
        })
        .finally(() => {
          return [];
        });
    },
    addloanagreement({ dispatch }, agreementRequest) {
      return apiAgreementRequest(agreementRequest);
    }
  },
  getters: {
    getLoansByDossierId: state => dossierId => {
      const loans = state.loansByDossierId[dossierId];
      return loans || [];
    }
  },
  mutations: {
    UPDATE_LOANS_BY_DOSSIERID: (state, params) => {
      const itemArray = {};
      itemArray[params.dossierId] = params.loans;
      state.loansByDossierId = Object.assign({}, itemArray);
    }
  },
  state: {
    loansByDossierId: {}
  }
};

import { WebStorageStateStore } from 'oidc-client-ts';

// export const msalAuth = {
//   clientId: window.clientId,
//   tenantId: window.tenantId,
//   redirectUri: window.redirectUri
// };

// https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

// login with OneWelcome
export const msalAuthOneWelcome = {
  auth: {
    tenant: window.tenantId,
    clientId: '6b9e071c344111edace306498403492b', // window.clientId,
    authority: 'https://www.acc.loginpostnl.net:443/am/oauth2/',
    knownAuthorities: ['https://www.acc.loginpostnl.net:443/am/oauth2/', 'https://login.microsoftonline.com/common/'],
    // authorityMetadata: '{"response_types_supported":["code token id_token","code","code id_token","id_token","code token","token","token id_token"],"claims_parameter_supported":false,"end_session_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/connect/endSession","scopes_supported":["uid","openid","profile","email","credential:imi:enrol:qr:post","apperianGroups","vhs_role"],"version":"3.0","id_token_encryption_enc_values_supported":["A128CBC-HS256","A256CBC-HS512"],"acr_values_supported":[],"issuer":"https://www.acc.loginpostnl.net:443/am/oauth2","userinfo_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/userinfo","authorization_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/authorize","claims_supported":[],"id_token_encryption_alg_values_supported":["RSA1_5"],"jwks_uri":"https://www.acc.loginpostnl.net:443/am/oauth2/connect/jwk_uri","subject_types_supported":["public"],"id_token_signing_alg_values_supported":["RS256"],"token_endpoint_auth_methods_supported":["client_secret_post"],"token_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/access_token"}',
    redirectUri: 'https://localhost:8080', // window.redirectUri, // It has to be configured on your Azure tenant
    scopes: ['openid'],
    protocolMode: 'OIDC'
    // navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage' // 'localStorage'
  },
  // graph: {
  //   url: '<your-graph-api-url>',
  //   scopes: '<your-graph-api-scopes>',
  //   response_type: "blob"
  // },
  mode: 'redirect'
};

export const oidcSettings = {
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  authority: window.authority,
  client_id: window.clientId,
  redirect_uri: window.redirectUri,
  popup_redirect_uri: window.redirectUri,
  response_type: 'code',
  response_mode: 'query',
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  silent_redirect_uri: window.redirectUri,
  scope: 'openid',
  post_logout_redirect_uri: window.redirectUri,
  max_age: 36000,
  accessTokenExpiringNotificationTimeInSeconds: 300,
  loadUserInfo: false
};

// Login with microsoft
export const msalAuthMicrosoft = {
  auth: {
    tenant: window.tenantId,
    clientId: window.clientId,
    authority: 'https://login.microsoftonline.com/common/',
    // knownAuthorities: ['https://www.acc.loginpostnl.net:443/am/oauth2/', 'https://login.microsoftonline.com/common/'],
    // authorityMetadata: '{"response_types_supported":["code token id_token","code","code id_token","id_token","code token","token","token id_token"],"claims_parameter_supported":false,"end_session_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/connect/endSession","scopes_supported":["uid","openid","profile","email","credential:imi:enrol:qr:post","apperianGroups","vhs_role"],"version":"3.0","id_token_encryption_enc_values_supported":["A128CBC-HS256","A256CBC-HS512"],"acr_values_supported":[],"issuer":"https://www.acc.loginpostnl.net:443/am/oauth2","userinfo_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/userinfo","authorization_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/authorize","claims_supported":[],"id_token_encryption_alg_values_supported":["RSA1_5"],"jwks_uri":"https://www.acc.loginpostnl.net:443/am/oauth2/connect/jwk_uri","subject_types_supported":["public"],"id_token_signing_alg_values_supported":["RS256"],"token_endpoint_auth_methods_supported":["client_secret_post"],"token_endpoint":"https://www.acc.loginpostnl.net:443/am/oauth2/access_token"}',
    redirectUri: window.redirectUri, // It has to be configured on your Azure tenant
    scopes: ['user.read']
  },
  cache: {
    cacheLocation: 'sessionStorage' // 'localStorage'
  },
  // graph: {
  //   url: '<your-graph-api-url>',
  //   scopes: '<your-graph-api-scopes>',
  //   response_type: "blob"
  // },
  mode: 'redirect'
};

export default msalAuthOneWelcome;
// export default msalAuthMicrosoft;

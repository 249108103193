const baseMutations = {
  CLEAR_ITEMS: state => {
    state.totalItemsCount = 0;
    state.items = [];
  },
  CLEAR_LOOSE_ITEMS: state => {
    state.looseItems = [];
  },
  CLEAR_PAGINATED_ITEMS: state => {
    state.paginatedItems = [];
  },
  SET_VERSION: (state, version) => {
    state.version = version;
  },
  SET_LOOSE_VERSION: (state, version) => {
    state.looseVersion = version;
  },
  DELETE_ITEM: (state, item) => {
    const index = state.items.findIndex(element => element.id === item.id);
    if (index !== -1) {
      state.items.splice(index, 1);
    }
  },
  DELETE_PAGINATED_ITEM: (state, item) => {
    const index = state.paginatedItems.findIndex(element => element.id === item.id);
    if (index !== -1) {
      state.paginatedItems.splice(index, 1);
    }
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_TOTAL_ITEM_COUNT: (state, count) => {
    state.totalItemsCount = count;
  },
  UPSERT_ITEM: (state, item) => {
    const index = state.items.findIndex(element => element.id === item.id);
    if (index !== -1) {
      state.items.splice(index, 1, item);
    } else {
      state.items.push(item);
    }
  },
  UPSERT_LOOSE_ITEM: (state, item) => {
    const index = state.looseItems.findIndex(element => element.id === item.id);
    if (index !== -1) {
      state.looseItems.splice(index, 1, item);
    } else {
      state.looseItems.push(item);
    }
  },
  UPSERT_PAGINATED_ITEM: (state, item) => {
    const index = state.paginatedItems.findIndex(element => element.id === item.id);
    if (index !== -1) {
      state.paginatedItems.splice(index, 1, item);
    } else {
      state.paginatedItems.push(item);
    }
  }
};

export default baseMutations;

import { getWeek, getMonday, nextWeekOf } from '@/utils/date';

function getWeekNumbers(date, count) {
  let currentMonday = getMonday(date.setDate(1));
  const weekNumbers = [];
  for (let i = 0; i < count; i++) {
    let week;

    if (i === 0) {
      week = getWeek(currentMonday);
    } else {
      week = weekNumbers[i - 1] + 1;
      if (week >= 53) {
        week = getWeek(currentMonday);
      }
    }
    weekNumbers.push(week);

    currentMonday = nextWeekOf(currentMonday);
  }
  return weekNumbers;
}

function wrapInTableRows(weekNumbers) {
  return weekNumbers.reduce((acc, nr) => acc + `<tr><td>${nr}</td></tr>`, '');
}

export function drawWeekNumbers(panel, date) {
  const weekNumbers = getWeekNumbers(date, 6);

  const weekNumberTable = document.createElement('table');

  weekNumberTable.classList.add('date-picker-week-table');
  weekNumberTable.innerHTML = `<tbody><tr><th>Wk</th></tr>${wrapInTableRows(weekNumbers)}</tbody>`;
  panel.insertBefore(weekNumberTable, panel.firstChild);
}
